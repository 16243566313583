// src/components/ui/Form.tsx

import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'

interface FormProps {
  children: React.ReactNode
  onSubmit: (data: unknown) => void
  defaultValues?: Record<string, unknown>
}

const Form = ({ children, onSubmit, defaultValues }: FormProps) => {
  const methods = useForm({ defaultValues })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  )
}

export default Form
