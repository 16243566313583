import { Link } from '@tanstack/react-router'
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from './ui/navigation-menu'

import { NavigationMenuLink } from './ui/navigation-menu'
import { navClassName } from './nav-utils'

const navList = [
  {
    name: 'Exercises',
    href: '/exercises',
  },
  {
    name: 'Programs',
    href: '/programs',
  },
  {
    name: 'Workouts',
    href: '/workouts',
  },
]

export const NavItem = ({ item }: { item: (typeof navList)[0] }) => {
  return (
    <Link to={item.href}>
      <NavigationMenuLink className={navClassName}>
        {item.name}
      </NavigationMenuLink>
    </Link>
  )
}
export function NavItems() {
  return navList.map((item) => (
    <NavigationMenuItem key={item.name}>
      <NavItem item={item} />
    </NavigationMenuItem>
  ))
}

export default function Header() {
  return (
    <header className="mb-4 flex w-screen justify-between border-solid bg-secondary px-6 py-4">
      <Link className="text-primary text-2xl font-bold" to="/home">
        AutoLifts
      </Link>

      <nav className="dark flex grow justify-end">
        <NavigationMenu>
          <NavigationMenuList>
            <NavItems />
            <NavigationMenuItem className="px-2 transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110">
              {/* // TODO:Signout flow */}
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
      </nav>
    </header>
  )
}
