// src/pages/ExercisesPage.tsx

import React, { useState, Suspense } from 'react'
import { Button } from '../components/ui/button'
import Heading from '../components/ui/heading'

// import { deleteExercise } from '../services/api'
import { Plus } from 'lucide-react'
// import {
//   useQueryClient,
// useMutation,
// useSuspenseQuery,
// } from '@tanstack/react-query'
import CreateExerciseModal from '../components/exercises/create-exercise-modal'
// import EditExerciseModal from '../components/exercises/edit-exercise-modal'
// import Text from '../components/ui/text'
import ExerciseFilter from '../components/exercises/exercise-filter'
// import { Exercise } from '../services/types'
import { ExerciseList } from '../components/exercises/exercise-list'

const ExercisesPage: React.FC = () => {
  // const queryClient = useQueryClient()

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  // const [selectedExercise, setSelectedExercise] = useState<
  //   | (Omit<Exercise, 'muscle_group_id' | 'movement_type_id'> & {
  //       muscle_group_name: string
  //       movement_type_name: string
  //     })
  //   | null
  // >(null)

  // if (error) {
  //   return <div>Error fetching exercises</div>
  // }

  // const handleEdit = (id: number) => {
  //   const exerciseToEdit = exercises?.find((ex) => ex.id === id)
  //   if (exerciseToEdit) {
  //     setSelectedExercise(exerciseToEdit)
  //     setIsEditModalOpen(true)
  //   }
  // }

  // const handleDelete = (id: number) => {
  //   if (confirm('Are you sure you want to delete this exercise?')) {
  //     deleteMutation.mutate(id)
  //   }
  // }

  return (
    <Suspense
      fallback={
        <div className="flex flex-col min-h-screen">Loading exercises</div>
      }
    >
      <div className="flex flex-col min-h-screen">
        <main className="flex-grow container mx-auto px-4 py-8">
          <div className="flex justify-between items-center mb-6">
            <Heading level={1} className="text-3xl">
              Exercises
            </Heading>
            <Button
              variant="primary"
              onClick={() => setIsCreateModalOpen(true)}
              aria-label="Create Exercise"
            >
              <Plus className="mr-2 h-4 w-4" />
              Create Exercise
            </Button>
          </div>
          <Suspense fallback={<div>Loading...</div>}>
            <ExerciseFilter />
            {/* {isLoading && <Text>Loading exercises...</Text>}
            {error && (
              <Text className="text-danger">Error loading exercises.</Text>
            )} */}
            <ExerciseList />
          </Suspense>
        </main>
        <CreateExerciseModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
        />
        {/* {selectedExercise && (
          <EditExerciseModal
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            exercise={selectedExercise}
          />
        )} */}
      </div>
    </Suspense>
  )
}

// const ExercisesPage: React.FC = () => {
//   return (
//     <div>
//       <ExercisesHeader />
//       <Suspense fallback={<div>Loading...</div>}>
//         <ExerciseList />
//       </Suspense>
//     </div>
//   )
// }

export default ExercisesPage
