import { createFileRoute, Outlet } from '@tanstack/react-router'
import WorkoutsPage from '../pages/workout-page'

export const Route = createFileRoute('/workouts')({
  component: () => (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
        <WorkoutsPage />
        <Outlet />
      </main>
    </div>
  ),
})
