import { cn } from '../lib/utils'
import { navigationMenuTriggerStyle } from './ui/navigation-menu'

export const baseNavClassName = cn(
  'text-sm leading-tight',
  'bg-transparent',
  'leading-tight',
  'text-muted-foreground',
  'hover:text-primary',
  'hover:underline',
)
export const navClassName = cn(navigationMenuTriggerStyle(), baseNavClassName)
