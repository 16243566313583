// src/components/ui/Text.tsx

import React from 'react'
import { cn } from '../../lib/utils'

interface TextProps {
  className?: string
  children: React.ReactNode
}

const Text: React.FC<TextProps> = ({ className, children }) => {
  return <p className={cn('text-base text-gray-700', className)}>{children}</p>
}

export default Text
