import { createRootRoute, Outlet } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import Header from '../components/header'
import Footer from '../components/footer'

const App = () => (
  <div className="flex flex-col min-h-screen">
    {/* Add your common layout components like header or navigation here */}
    <Header />
    <Outlet />
    <TanStackRouterDevtools />
    <Footer />
  </div>
)

export const Route = createRootRoute({ component: App })
