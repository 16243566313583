import {
  Workout,
  NewWorkout,
  NewWorkoutExercise,
  WorkoutExercise,
  Sets,
  Exercise,
  NewSet,
} from '../../services/types'

const API_BASE_URL =
  import.meta.env.MODE === 'development'
    ? import.meta.env.VITE_API_URL_DEV
    : import.meta.env.VITE_API_URL_PROD

export const fetchWorkouts = async (): Promise<Workout[]> => {
  const response = await fetch(`${API_BASE_URL}/workouts`)
  if (!response.ok) {
    throw new Error('Failed to fetch workouts')
  }
  return response.json()
}

export const fetchWorkout = async (
  workoutId: string,
): Promise<{
  workout: Workout
  workoutExercises: WorkoutExercise[]
  sets: Sets[]
}> => {
  const response = await fetch(`${API_BASE_URL}/workouts/${workoutId}`)
  if (!response.ok) {
    throw new Error('Failed to fetch workout')
  }
  return response.json()
}

export const createWorkout = async ({
  workout,
  workoutExercises,
}: {
  workout: NewWorkout
  workoutExercises: NewWorkoutExercise[]
  sets: NewSet[]
}): Promise<Workout> => {
  const response = await fetch(`${API_BASE_URL}/workouts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ workout, workoutExercises, sets }),
  })
  if (!response.ok) {
    const errorData = await response.json()
    throw new Error(errorData.error || 'Failed to create workout')
  }
  return response.json()
}

export const createWorkoutExercises = async (
  workoutExercise: NewWorkoutExercise[],
): Promise<WorkoutExercise[]> => {
  const response = await fetch(
    `${API_BASE_URL}/workouts/${workoutExercise[0].workout_id}/exercises`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(workoutExercise),
    },
  )
  if (!response.ok) {
    const errorData = await response.json()
    throw new Error(errorData.error || 'Failed to create workout exercise')
  }
  return response.json()
}

export const fetchWorkoutExercises = async (
  workoutId: string,
): Promise<
  {
    workoutExercise: WorkoutExercise
    exercise: Exercise
    set: Sets
  }[]
> => {
  const res = await fetch(`${API_BASE_URL}/workouts/${workoutId}/exercises`)
  console.log('aaa', res)

  const response = (await res.json()) as {
    workout_exercise: WorkoutExercise
    exercise: Exercise
    set: Sets
  }[]
  return response.map(({ workout_exercise, exercise, set }) => ({
    workoutExercise: workout_exercise,
    exercise: exercise,
    set: set,
  }))
}

export type WorkoutSummary = {
  adjustments: {
    adjustedWeight: number
    adjustment: number
    avg_rpe: Record<string, number>
    message: string
  }[]
  muscleGroupVolumes: Record<string, number>
  totalVolume: number
}

export const fetchWorkoutSummary = async (
  workoutId: string,
): Promise<WorkoutSummary> => {
  const res = await fetch(`${API_BASE_URL}/workouts/${workoutId}/summary`)
  return res.json()
}
