// TrackWorkout.tsx

import React, { useState, useEffect, Suspense } from 'react'
import { getRouteApi } from '@tanstack/react-router'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { Slider } from '../ui/slider'
import { createSet } from '../../services/api'
import { fetchWorkoutSummary } from '../../lib/api/workouts'
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from '../ui/card'
import { Progress } from '../ui/progress'
import { Exercise, Sets, WorkoutExercise } from '../../services/types'

interface SetData {
  id?: string
  weight: number
  reps: number
  rpe: number | null
  completed: boolean
}

const route = getRouteApi('/workouts/$workoutId/track/')
const TrackWorkout: React.FC = () => {
  const { workoutId } = route.useParams()
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0)
  const [currentSetIndex, setCurrentSetIndex] = useState(0)
  const [timer, setTimer] = useState<number | null>(null)
  const [weight, setWeight] = useState('')
  const [reps, setReps] = useState('')
  const [rpe, setRpe] = useState<number>(7)
  const queryClient = useQueryClient()
  const { workout, workoutExercises, sets } = route.useLoaderData()
  console.log(workout, workoutExercises, sets)

  const addSetMutation = useMutation({
    mutationKey: ['workouts', 'set', workoutId],
    mutationFn: async (setData: SetData) => {
      const workoutExerciseId = workoutExercises![currentExerciseIndex].id
      const data = await createSet({
        ...setData,
        workout_exercise_id: workoutExerciseId,
        id: setData.id ? parseInt(setData.id, 10) : undefined,
      })

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['workouts', 'sets', workoutId],
      })
    },
  })

  const handleSetCompletion = async () => {
    addSetMutation.mutate({
      weight: parseFloat(weight),
      reps: parseInt(reps, 10),
      rpe,
      completed: true,
    })

    setWeight('')
    setReps('')
    setRpe(7)

    const restTime = workoutExercises![currentExerciseIndex].rest_timer
    setTimer(restTime)

    if (currentSetIndex + 1 < workoutExercises![currentExerciseIndex].sets) {
      setCurrentSetIndex(currentSetIndex + 1)
    } else {
      if (currentExerciseIndex + 1 < workoutExercises!.length) {
        setCurrentExerciseIndex(currentExerciseIndex + 1)
        setCurrentSetIndex(0)
      } else {
        const summary = await fetchWorkoutSummary(workoutId)
        console.log(summary)
        alert('Workout complete!')
      }
    }
  }

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (timer !== null && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer !== null ? prevTimer - 1 : null))
      }, 1000)
    } else if (timer === 0) {
      const audio = new Audio('/timer-end.mp3')
      audio.play()
      setTimer(null)
    }
    return () => clearInterval(interval)
  }, [timer])

  return (
    <div className="p-6 max-w-2xl mx-auto">
      <Suspense fallback={<div>Loading...</div>}>
        {workoutExercises && workoutExercises.length > 0 && (
          <Card className="shadow-lg">
            <CardHeader>
              <CardTitle className="text-2xl font-bold">
                {workoutExercises[currentExerciseIndex].exercise.name}
              </CardTitle>
              <p className="text-gray-500">
                Set {currentSetIndex + 1} of{' '}
                {workoutExercises[currentExerciseIndex].sets}
              </p>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Input
                  label="Weight"
                  type="number"
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                  required
                />
                <Input
                  label="Reps"
                  type="number"
                  value={reps}
                  onChange={(e) => setReps(e.target.value)}
                  required
                />
                <div className="md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    RPE: {rpe}
                  </label>
                  <Slider
                    defaultValue={[rpe]}
                    min={6}
                    max={10}
                    step={0.5}
                    onValueChange={(value) => setRpe(value[0])}
                  />
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex flex-col md:flex-row items-center justify-between">
              <Button
                onClick={handleSetCompletion}
                className="w-full md:w-auto"
              >
                Complete Set
              </Button>
              {timer !== null && (
                <div className="mt-4 md:mt-0 w-full md:w-auto">
                  <p className="text-center font-medium mb-1">
                    Rest Timer: {timer} seconds
                  </p>
                  <Progress
                    value={
                      ((workoutExercises[currentExerciseIndex].rest_timer -
                        timer) /
                        workoutExercises[currentExerciseIndex].rest_timer) *
                      100
                    }
                    className="w-full"
                  />
                </div>
              )}
            </CardFooter>
          </Card>
        )}
      </Suspense>
    </div>
  )
}

export default TrackWorkout
