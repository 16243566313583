import { createFileRoute, Outlet } from '@tanstack/react-router'
import ExercisesPage from '../pages/exercise-page'
import { fetchExercises } from '../services/api'

export const Route = createFileRoute('/exercises')({
  component: ExercisesLayout,
  validateSearch: (search) =>
    search as {
      movementType: string
      muscleGroup: string
    },
  loaderDeps: ({ search: { muscleGroup, movementType } }) => ({
    muscleGroup,
    movementType,
  }),
  loader: ({ deps: { muscleGroup, movementType } }) =>
    fetchExercises(muscleGroup, movementType),
})

function ExercisesLayout() {
  return (
    <div className="p4 container mx-auto">
      <main className="flex-grow">
        <ExercisesPage />
        <Outlet />
      </main>
    </div>
  )
}
