import { Suspense } from 'react'
import { MovementType, MuscleGroup, NewExercise } from '../../services/types'

import { Input } from '../ui/input'
import { Textarea } from '../ui/textarea'
import Select from '../ui/select'
import { fetchMuscleGroupsAndMovementTypes } from '../../services/api'
import { useSuspenseQuery } from '@tanstack/react-query'
import Form from '../ui/form'

type Props = {
  formData: NewExercise
  handleChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => void
  handleSubmit: () => void
}

export default function ExerciseForm({
  handleChange,
  handleSubmit,
  formData,
}: Props) {
  const {
    data: { movementTypes, muscleGroups },
  } = useSuspenseQuery<{
    muscleGroups: MuscleGroup[]
    movementTypes: MovementType[]
  }>({
    queryKey: ['muscle_groups'],
    queryFn: async () => {
      const data = await fetchMuscleGroupsAndMovementTypes()
      return data
    },
  })
  const handleCategoryChange = (value: string) =>
    handleChange({
      target: { name: 'movement_type_name', value },
    } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>)
  const handleMuscleGroupChange = (value: string) =>
    handleChange({
      target: { name: 'muscle_group_name', value },
    } as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>)

  return (
    <Form onSubmit={handleSubmit}>
      <div className="space-y-4">
        <Input
          label="Exercise Name"
          name="name"
          placeholder="Exercise Name"
          value={formData?.name ?? ''}
          onChange={handleChange}
          required
        />
        <Textarea
          label="Exercise Description"
          name="description"
          placeholder="Description"
          value={formData.description ?? ''}
          onChange={handleChange}
          required
        />
        <div className="flex flex-grow justify-between">
          <Suspense fallback={<div>Loading...</div>}>
            <Select
              className="w-full"
              label="Movement Type"
              options={movementTypes.map((x) => ({
                label: x.name,
                value: x.name,
              }))}
              onChange={(e) => handleCategoryChange(e.target.value)}
              value={formData.movement_type_name}
            />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <Select
              className="w-full ml-4"
              label="Muscle Group"
              name="muscle_group_name"
              options={muscleGroups.map((x) => ({
                label: x.name,
                value: x.name,
              }))}
              onChange={(e) => handleMuscleGroupChange(e.target.value)}
              value={formData.movement_type_name}
            />
          </Suspense>
        </div>
        <Input
          placeholder="Add an Image URL"
          onChange={handleChange}
          label="Image URL"
          name="image_url"
          value={formData.image_url ?? ''}
        />
      </div>
    </Form>
  )
}
