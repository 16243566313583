// src/components/CreateExerciseModal.tsx

import React, { Suspense, useState } from 'react'
import Modal from '../ui/modal'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createExercise } from '../../services/api'
import ExerciseForm from './exercise-form'

interface CreateExerciseModalProps {
  isOpen: boolean
  onClose: () => void
}

const CreateExerciseModal: React.FC<CreateExerciseModalProps> = ({
  isOpen,
  onClose,
}) => {
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationKey: ['exercises'],
    mutationFn: createExercise,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['exercises'] })
      onClose()
    },
  })

  const [formData, setFormData] = useState<{
    name: string
    muscle_group_name: string
    movement_type_name: string
  }>(() => ({
    name: '',
    muscle_group_name: '',
    movement_type_name: '',
  }))

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => {
    mutation.mutate(formData)
  }

  return (
    <Modal
      className="min-w-7/12"
      isOpen={isOpen}
      confirmLabel="Create Exercise"
      onConfirm={handleSubmit}
      onClose={onClose}
      title="Create New Exercise"
    >
      <Suspense fallback={<div>Loading...</div>}>
        <ExerciseForm
          formData={formData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </Suspense>
    </Modal>
  )
}

export default CreateExerciseModal
