// Define the Ok variant
export interface Ok<T> {
  readonly type: 'ok'
  readonly value: T
}

// Define the Error variant
export interface Err<E> {
  readonly type: 'error'
  readonly error: E
}

// The Result type is a union of Ok and Err
export type Result<T, E> = Ok<T> | Err<E>

// Add a method to the Result type using a namespace
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Result {
  export function andThen<T, E, U>(
    result: Result<T, E>,
    f: (value: T) => Result<U, E>,
  ): Result<U, E> {
    if (isOk(result)) {
      return f(result.value)
    } else {
      return result
    }
  }
}
// Helper function to create an Ok result
export const ok = <T>(value: T): Result<T, never> => ({
  type: 'ok',
  value,
})

// Helper function to create an Err result
export const error = <E>(error: E): Result<never, E> => ({
  type: 'error',
  error,
})
// Type guard to check if the result is Ok
export const isOk = <T, E>(result: Result<T, E>): result is Ok<T> =>
  result.type === 'ok'

// Type guard to check if the result is Err
export const isError = <T, E>(result: Result<T, E>): result is Err<E> =>
  result.type === 'error'

export const handleResult = <T>(result: T): Result<T, Error> => {
  if (result instanceof Error) {
    return error(result)
  }
  return ok(result)
}
