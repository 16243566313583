// src/pages/WorkoutsPage.tsx

import React, { Suspense } from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Link } from '@tanstack/react-router'
import { Button } from '../components/ui/button'
import { fetchWorkouts } from '../lib/api/workouts'

const WorkoutsPage: React.FC = () => {
  const {
    data: workouts,
    isLoading,
    error,
  } = useSuspenseQuery({ queryKey: ['workouts'], queryFn: fetchWorkouts })

  if (isLoading) return <div className="p-8">Loading workouts...</div>
  if (error) return <div className="p-8">Error: {(error as Error).message}</div>

  return (
    <div className="p-8">
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl font-bold">Workouts</h1>
        <Button asChild variant={'link'}>
          <Link to="/workouts/create">Create Workout</Link>
        </Button>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <ul className="space-y-4">
          {workouts?.map((workout) => (
            <li key={workout.id} className="border p-4 rounded">
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-xl font-semibold">{workout.name}</h2>
                  <p className="text-gray-600">
                    Date: {new Date(workout.date).toLocaleDateString()}
                  </p>
                </div>
                <Button variant="secondary">
                  <Link to={`/workouts/${workout.id.toString()}`}>
                    Track Workout
                  </Link>
                </Button>
              </div>
            </li>
          ))}
        </ul>
      </Suspense>
    </div>
  )
}

export default WorkoutsPage
