import { createFileRoute } from '@tanstack/react-router'
import TrackWorkout from '../components/workouts/track-workout'
import { fetchWorkout } from '../lib/api/workouts'

export const Route = createFileRoute('/workouts/$workoutId/track/')({
  loader: ({ params: { workoutId } }) => fetchWorkout(workoutId),
  component: TrackWorkoutPage,
})

function TrackWorkoutPage() {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow container mx-auto px-4 py-8">
        <TrackWorkout />
      </main>
    </div>
  )
}
