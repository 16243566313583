// src/components/ui/Heading.tsx

import React from 'react'
import { cn } from '../../lib/utils'

interface HeadingProps {
  level?: 1 | 2 | 3 | 4 | 5 | 6
  className?: string
  children: React.ReactNode
}

const Heading: React.FC<HeadingProps> = ({
  level = 1,
  className,
  children,
}) => {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements
  const sizes = {
    1: 'text-3xl font-bold',
    2: 'text-2xl font-semibold',
    3: 'text-xl font-semibold',
    4: 'text-lg font-medium',
    5: 'text-base font-medium',
    6: 'text-sm font-medium',
  }

  return <Tag className={cn(sizes[level], className)}>{children}</Tag>
}

export default Heading
