// src/components/Footer.tsx

import React from 'react'
// import { Link } from '@tanstack/react-router'

const Footer: React.FC = () => {
  return (
    <footer className="bg-white shadow mt-8">
      <div className="container mx-auto px-4 py-4 flex flex-col sm:flex-row justify-between items-center">
        <p className="text-sm text-gray-600">
          &copy; {new Date().getFullYear()} Fitness App. All rights reserved.
        </p>
        <div className="flex space-x-4 mt-2 sm:mt-0">
          <p className="text-sm text-gray-600 hover:text-primary">Contact Us</p>
          <p className="text-sm text-gray-600 hover:text-primary">
            Privacy Policy
          </p>
          <p className="text-sm text-gray-600 hover:text-primary">
            Terms of Service
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
