// src/services/api.ts

import { handleResult, Ok, Result } from '../types/Result'
import {
  Exercise,
  MovementType,
  MuscleGroup,
  NewExercise,
  NewProgram,
  NewSet,
  Program,
  Sets,
} from './types'

const API_BASE_URL =
  import.meta.env.MODE === 'development'
    ? import.meta.env.VITE_API_URL_DEV
    : import.meta.env.VITE_API_URL_PROD

type MuscleGroupAndMovementType = {
  muscleGroups: MuscleGroup[]
  movementTypes: MovementType[]
}

export const fetchMuscleGroupsAndMovementTypes =
  async (): Promise<MuscleGroupAndMovementType> => {
    const response = await fetch(
      `${API_BASE_URL}/muscle-groups-and-movement-types`,
    )
    if (!response.ok) {
      throw new Error('Failed to fetch muscle groups')
    }
    return response.json()
  }

export const fetchExercises = async (
  muscleGroupName: string = '',
  movementTypeName: string = '',
): Promise<
  (Omit<Exercise, 'muscle_group_id' | 'movement_type_id'> & {
    muscle_group_name: string
    movement_type_name: string
  })[]
> => {
  const params = new URLSearchParams({ muscleGroupName, movementTypeName })
  let url = `${API_BASE_URL}/exercises`
  if (muscleGroupName || movementTypeName) url = `${url}?${params.toString()}`

  const response = await fetch(url)

  if (!response.ok) {
    throw new Error('Failed to fetch exercises')
  }
  const res = await response.json()

  return res as (Omit<Exercise, 'muscle_group_id' | 'movement_type_id'> & {
    muscle_group_name: string
    movement_type_name: string
  })[]
}

export const createExercise = async (
  exercise: NewExercise,
): Promise<Exercise> => {
  const response = await fetch(`${API_BASE_URL}/exercises`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(exercise),
  })
  if (!response.ok) {
    const errorData = await response.json()
    throw new Error(errorData.error || 'Failed to create exercise')
  }
  return response.json()
}

export const updateExercise = async (
  id: string,
  updates: Partial<{
    name: string
    muscle_group_name: string
    movement_type_name: string
  }>,
): Promise<Exercise> => {
  const response = await fetch(`${API_BASE_URL}/exercises/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updates),
  })
  if (!response.ok) {
    const errorData = await response.json()
    throw new Error(errorData.error || 'Failed to update exercise')
  }
  return response.json()
}

export const deleteExercise = async (id: number): Promise<void> => {
  const response = await fetch(`${API_BASE_URL}/exercises/${id}`, {
    method: 'DELETE',
  })
  if (!response.ok) {
    const errorData = await response.json()
    throw new Error(errorData.error || 'Failed to delete exercise')
  }
  return
}

export const fetchPrograms = async (): Promise<Program[]> => {
  const response = await fetch(`${API_BASE_URL}/programs`)
  if (!response.ok) {
    throw new Error('Failed to fetch programs')
  }
  return response.json()
}

export const createProgram = async (program: NewProgram): Promise<Program> => {
  const response = await fetch(`${API_BASE_URL}/programs`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(program),
  })
  if (!response.ok) {
    const errorData = await response.json()
    throw new Error(errorData.error || 'Failed to create program')
  }
  return response.json()
}

export const createSet = async (set: NewSet): Promise<Result<Sets, Error>> => {
  const response = await fetch(
    `${API_BASE_URL}/workout-exercises/${set.workout_exercise_id}/sets`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(set),
    },
  )

  const res = handleResult(await response.json())
  if (res.type === 'error') {
    throw res.error
  }

  return res as Ok<Sets>
}

export const updateSet = async (
  id: string,
  updates: Partial<{
    weight: number
    reps: number
    rpe: number | null
    completed: boolean
  }>,
): Promise<Sets> => {
  const response = await fetch(`${API_BASE_URL}/sets/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updates),
  })
  if (!response.ok) {
    const errorData = await response.json()
    throw new Error(errorData.error || 'Failed to update set')
  }
  return response.json()
}

export const deleteSet = async (id: string) => {
  const response = await fetch(`${API_BASE_URL}/sets/${id}`, {
    method: 'DELETE',
  })
  if (!response.ok) {
    const errorData = await response.json()
    throw new Error(errorData.error || 'Failed to delete set')
  }
  return
}

export const fetchSets = async (workoutExerciseId: string): Promise<Sets[]> => {
  const response = await fetch(
    `${API_BASE_URL}/workout-exercises/${workoutExerciseId}/sets`,
  )
  if (!response.ok) {
    throw new Error('Failed to fetch sets')
  }
  return response.json()
}
