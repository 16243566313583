import { Store, useStore } from '@tanstack/react-store'
import { getRouteApi } from '@tanstack/react-router'
export const filterStore = new Store<{
  movementType: string
  muscleGroup: string
}>({
  movementType: '',
  muscleGroup: '',
})

const exerciseRoute = getRouteApi('/exercises')

export const setMovementType = (movementType: string) =>
  filterStore.setState((prev) => ({ ...prev, movementType }))

export const setMuscleGroup = (muscleGroup: string) =>
  filterStore.setState((prev) => ({ ...prev, muscleGroup }))

export const useFilterStore = () => {
  const store = useStore(filterStore, (state) => state)
  const navigate = exerciseRoute.useNavigate()
  return {
    ...store,
    setMovementType: (movementType: string) => {
      setMovementType(movementType)
      navigate({
        to: '/exercises',
        search: {
          movementType,
          muscleGroup: store.muscleGroup,
        },
      })
    },
    setMuscleGroup: (muscleGroup: string) => {
      setMuscleGroup(muscleGroup)
      navigate({
        to: '/exercises',
        search: {
          muscleGroup,
          movementType: store.movementType,
        },
      })
    },
  }
}
