import React, { useMemo } from 'react'
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '../ui/tooltip'
import { fetchExercises } from '../../services/api'
import Text from '../ui/text'
import Heading from '../ui/heading'
import { Separator } from '../ui/separator'
import { getRouteApi } from '@tanstack/react-router'

// Function to truncate text
const truncate = (text: string, maxLength: number) => {
  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text
}

// Function to group exercises by muscle group
const groupExercisesByMuscleGroup = (
  exercises: Awaited<ReturnType<typeof fetchExercises>>,
) => {
  return exercises.reduce(
    (acc, exercise) => {
      const muscleGroup = exercise.muscle_group_name || 'Uncategorized'
      if (!acc[muscleGroup]) {
        acc[muscleGroup] = []
      }
      acc[muscleGroup].push(exercise)
      return acc
    },
    {} as Record<string, Awaited<ReturnType<typeof fetchExercises>>>,
  )
}
const exerciseRoute = getRouteApi('/exercises')
export function ExerciseList() {
  const exercises = exerciseRoute.useLoaderData()

  const groupedExercises = groupExercisesByMuscleGroup(exercises)
  const exerciseEntries = useMemo(
    () => Object.entries(groupedExercises),
    [groupedExercises],
  )

  return (
    <div className="space-y-8">
      {exerciseEntries.map(([muscleGroup, groupExercises]) => (
        <div key={muscleGroup}>
          <Heading level={2} className="mb-4">
            {muscleGroup}
          </Heading>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
            {groupExercises.map((exercise) => (
              <ExerciseCard key={exercise.id} exercise={exercise} />
            ))}
          </div>
          <Separator className="my-8" />
        </div>
      ))}
    </div>
  )
}

interface ExerciseCardProps {
  exercise: Awaited<ReturnType<typeof fetchExercises>>[0]
}

const ExerciseCard: React.FC<ExerciseCardProps> = ({ exercise }) => (
  <div className="relative rounded-lg bg-card p-4 text-card-foreground shadow-md">
    <Heading level={3} className="mb-2 font-semibold">
      {exercise.name}
    </Heading>
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Text className="text-sm text-muted-foreground">
            {truncate(exercise?.description ?? '', 100)}
          </Text>
        </TooltipTrigger>
        <TooltipContent>
          <Text className="max-w-xs">{exercise.description ?? ''}</Text>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  </div>
)
