import React, { Suspense } from 'react'
// import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../ui/select';
import Select from '../ui/select'
import { useSuspenseQuery } from '@tanstack/react-query'
import { fetchMuscleGroupsAndMovementTypes } from '../../services/api'
import { MovementType, MuscleGroup } from '../../services/types'
import { useFilterStore } from '../../stores/filter-store'

const ExerciseFilter: React.FC = () => {
  const {
    muscleGroup: selectedMuscleGroup,
    movementType: selectedMovementType,
    setMovementType,
    setMuscleGroup,
  } = useFilterStore()
  const { data } = useSuspenseQuery<{
    muscleGroups: MuscleGroup[]
    movementTypes: MovementType[]
  }>({
    queryKey: ['muscle_groups'],
    queryFn: async () => {
      const data = await fetchMuscleGroupsAndMovementTypes()
      return data
    },
  })

  const { muscleGroups, movementTypes } = data ?? {}

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="flex flex-col sm:flex-row gap-4 mb-6">
        <Select
          label="Filter by Muscle Group"
          value={selectedMuscleGroup}
          onChange={(e) => setMuscleGroup(e.target.value)}
          options={[
            { value: '', label: 'All Muscle Groups' },
            ...(muscleGroups ?? []).map((mg) => ({
              value: mg.name,
              label: mg.name,
            })),
          ]}
        />
        <Select
          label="Filter by Movement Type"
          value={selectedMovementType}
          onChange={(e) => setMovementType(e.target.value)}
          options={[
            { value: '', label: 'All Movement Types' },
            ...(movementTypes ?? []).map((mt) => ({
              value: mt.name,
              label: mt.name,
            })),
          ]}
        />
      </div>
    </Suspense>
  )
}

export default ExerciseFilter
